<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0">{{ $t('myClients') }}</p>
            <CButton
							v-if="$store.state.userData.account_level == 1"
							size="sm"
							color="primary"
							class="px-4"
							@click="$store.commit('setNewUserModal', true)">{{ $t('addClient') }}</CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            :loading="$store.state.loadingClientList"
            striped
						:column-filter="{ external: true, lazy: false }"
            :items="$store.state.clientList"
            :fields="fields"
            :items-per-page="limit"
            :active-page="activePage"
						@update:column-filter-value="filtersChange"
						@update:column-filter-change="filtersChangeColumn"
          >
            <template #nombre="data">
              <td>
								<div class="d-flex flex-ro align-items-center">
									{{data.item.profile.nombre}}
									<CIcon
										v-if="data.item.parent_user_id == $store.state.userData.id_usuario"
										name="cil-check-circle" size="sm" class="ml-2" style="color: #90B902" v-c-tooltip="{content: $t('myChildUserMessage'), placement: 'right'}"/>
								</div>
              </td>
            </template>
            <template #apellido="data">
              <td>{{data.item.profile.apellido}}</td>
						</template>
            <template #telefono="data">
              <td>{{data.item.profile.telefono}}</td>
						</template>
            <template #rut="data">
              <td>{{data.item.profile.rut}}</td>
						</template>
            <template #email="data">
              <td>{{data.item.profile.email}}</td>
						</template>
            <template #birth="data">
              <td>{{data.item.profile.nacimiento}}</td>
						</template>
            <template #comuna="data">
              <td>{{data.item.profile.comuna}}</td>
						</template>
            <template #rut="data">
              <td>
								<div class="d-flex flex-ro align-items-center">
									{{data.item && data.item.profile && data.item.profile.rut ? format(data.item.profile.rut):'Sin Rut'}}
								</div>
              </td>
            </template>
            <template #actions="data">
              <td v-if="$store.state.userData.account_level == 1">
                <!-- <CButton size="sm" color="success" class="mx-1" @click="">
                  <CIcon name="cil-file" size="sm"/>
                </CButton> -->
                <CButton size="sm" color="success" class="mx-1" @click="chatWithUser(data)" :disabled="loadingNewChat">
                  <CIcon name="cil-chat-bubble" size="sm"/>
                </CButton>
                <CButton size="sm" color="warning" class="mx-1" @click="editUser(data)">
                  <CIcon name="cil-pencil" size="sm"/>
                </CButton>
                <CButton size="sm" color="danger" class="mx-1" @click="deleteUser(data)">
                  <CIcon name="cil-x-circle" size="sm"/>
                </CButton>
              </td>
							<td class="text-center" v-else>
								--
							</td>
            </template>
          </CDataTable>
					<CPagination
						v-if="$store.state.clientListTotal > 0"
						:activePage="skip + 1"
						:pages="Math.ceil($store.state.clientListTotal/limit)"
						align="center"
						@update:activePage="pageChange"
					/>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import usersData from './UsersData'
import {deleteUser} from '@/helpers/api/users'
import {getOrCreateUserChatRoom} from '@/helpers/api/user'
import { format } from 'rut.js'
import _debounce from 'lodash/debounce'

export default {
  name: 'Users',
  data () {
    return {
      items: usersData,
      fields: [
        { key: 'nombre', label: this.$i18n.t('name') },
        { key: 'apellido', label: this.$i18n.t('lastName') },
        { key: 'telefono', label: this.$i18n.t('phone') },
        { key: 'rut', label: 'Rut' },
        { key: 'email', label: this.$i18n.t('email') },
        { key: 'birth', label: this.$i18n.t('birth'), filter: false, filter: false },
        { key: 'comuna', label: this.$i18n.t('comuna'), filter: false },
        { key: 'actions', label: this.$i18n.t('actions'), filter: false }
      ],
			limit: 20,
			skip: 0,
      activePage: 1,
			loadingNewChat: false,
			filters: {}
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    '$store.state.userData': {
      immediate: true,
      handler (userData) {
        if(userData && !this.$store.state.clientList){
          this.getUsers()
        }
      }
    }
  },
  mounted(){
    this.getUsers()
  },
  methods: {
		format,
		async chatWithUser(data){
			this.loadingNewChat = true
			const datos = {
				user_id: this.$store.state.userData.id_usuario,
				called_user_id: data.item.profile.id_usuario,
			}
			const room = await getOrCreateUserChatRoom(datos)
			this.$store.dispatch('getUserChatRoomsList')
			this.$store.commit('setModalChatStatus', true)
			this.$store.commit('setSelectedRoom', room.user_room)
			this.loadingNewChat = false
		},
    getUsers(){
      this.$store.dispatch('getClientList', {type: 2, limit: this.limit, skip: this.skip, filters: this.filters})
    },
    editUser(data){
      this.$store.commit('setEditUserModal', {status: true, userData: data.item.profile})
    },
    async deleteUser(data){
			const confirmation = await confirm(this.$i18n.t('deleteAskToConfirm'))
			if(!confirmation){ return false }
			
      if(data && data.item && data.item && data.item.profile && data.item.profile.id_usuario){
        let completed = false
        const id_usuario = data.item.profile.id_usuario
        const deleted = await deleteUser({id_usuario})
        if(deleted.message){
          completed = true
					this.$store.dispatch('getClientsList')
        }
        this.$store.commit('addToast', {
          color: completed ? 'success':'warning',
          header: completed ? 'Usuario Eliminado':'Error',
          message: completed ? 'Se ha eliminado el usuario ' + data.item.profile.nombre + ' ' + data.item.profile.apellido:'Hubo un error en la operación, por favor intenta nuevamente.'
        })
      }
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
			this.skip = val - 1
			this.getUsers()
      // this.$router.push({ query: { page: val }})
    },
		filtersChange: _debounce(function(val) {
			this.filters = Object.fromEntries(Object.entries(val).filter(([_, v]) => v != null && v != ''))
			if(Object.keys(this.filters).length > 0){
				this.getUsers()
			}
		}, 800),
		filtersChangeColumn(val){
			console.log('filtersChangeColumn: ', val)
		}
  }
}
</script>
